import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

const Base = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  padding: ${p => p.padding}px;

  ${media.lessThan("medium")`
    padding: ${p => p.padding / 2 || 16}px;
  `}
  ${media.lessThan("small")`
    padding: ${p => p.padding / 3 || 16}px;
  `}
`;

const Quote = styled.div`
  color: var(--primary-text);
  font-family: Randy;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
  font-size: ${p => (p.inline ? 1.5 : 2.2)}rem;

  ${media.lessThan("medium")`
  font-size: ${p => (p.inline ? 1.5 : 2.2) / 1.2}rem;
  `}
  ${media.lessThan("small")`
  font-size: ${p => (p.inline ? 1.5 : 2.2) / 1.3}rem;
  `}
`;

const Source = styled.div`
  margin-top: 16px;
  font-size: 1rem;
  color: #9e9e9e;
  font-weight: 300;
  align-self: flex-end;
`;

export default function QuoteBox({ padding, source, quote, style, inline }) {
  return (
    <Base padding={padding} style={{ ...style }}>
      <Quote inline={inline}>{quote}</Quote>
      {source ? <Source>{source}</Source> : null}
    </Base>
  );
}
