import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import Youtube from "../Youtube";
import Section from "../Section";
import { SubSectionHeader } from "../Typo";
import { FEATURED_VIDEO_ID } from "../../featuredVideoId";

const Watch = styled.div`
  flex: 1;

  ${media.lessThan("medium")`
   & .yt {
     width: 100% !important;
     height: 50vw !important;
   }
  `}
`;

const Listen = styled.div`
  flex: 1;
  margin-left: 64px;

  ${media.lessThan("medium")`
    margin-left: 0px;
    margin-top: 32px;

    iframe {
      width: 100% !important;
    }
  `}
`;

export default function MediaBottom() {
  return (
    <Section flex>
      <Watch>
        <SubSectionHeader title="Kijk" icon="video" />
        <Youtube
          className="yt"
          videoId={FEATURED_VIDEO_ID}
          width="90%"
          height="225px"
        />
      </Watch>
      <Listen>
        <SubSectionHeader title="Luister" icon="audio" />
        <iframe
          title="spotify-bottom"
          src="https://open.spotify.com/embed/playlist/2No6DdeDsJKHw9QcGhDJYV"
          width="300"
          height="380"
          frameBorder="0"
          allow="encrypted-media"
        ></iframe>
      </Listen>
    </Section>
  );
}
